<template>
  <div>
    <b-card-body class="p-2">
      <MemberBetNumbers
        v-if="display==='Member'"
        :accountId="memberAccountId"
        :roundId="roundId"
        @data="setData"
        @Breadcrumbs="setBreadcrumbs" />
      <table v-else class="table-report-account table table-bordered table-primary mb-0">
        <thead>
          <tr>
            <th rowspan="2">ชื่อใช้งาน
              <b-form-checkbox
                v-model="showName"
                :value="true"
                :unchecked-value="false"
                class="mt-2 font-weight-normal"
              >
                แสดงชื่อ
              </b-form-checkbox>
            </th>
            <th width="6%" rowspan="2">ระดับ</th>
            <th width="8%" rowspan="2" style="border-right-width: 2px;">ยอดทั้งหมด</th>
            <th colspan="4" style="border-right-width: 2px;">สมาชิก</th>
            <th colspan="4" style="border-right-width: 2px;">{{agentName}}</th>
            <th colspan="4">บริษัท</th>
          </tr>
          <tr>
            <th width="6%" class="p-1">ส่งออก</th>
            <th width="6%" class="p-1">คอมฯ</th>
            <th width="6%" class="p-1">ถูกรางวัล</th>
            <th width="6%" class="p-1" style="border-right-width: 2px;">รวม</th>
            <th width="6%" class="p-1">ถือหุ้น</th>
            <th width="6%" class="p-1">คอมฯ</th>
            <th width="6%" class="p-1">ถูกรางวัล</th>
            <th width="6%" class="p-1" style="border-right-width: 2px;">รวม</th>
            <th width="6%" class="p-1">ถือหุ้น</th>
            <th width="6%" class="p-1">คอมฯ</th>
            <th width="6%" class="p-1">ถูกรางวัล</th>
            <th width="6%" class="p-1">รวม</th>
          </tr>
        </thead>
        <tbody v-if="accounts.length && !isLoading">
          <tr v-for="item in accounts" :key="item.agent.childAccountId">
            <td class="p-0 px-1 text-center">
              <a v-if="item.agent.role==='Agent'" href="#" @click="toggleChildAccount(item.agent.childAccountId)">{{ item.agent.username }}</a>
              <a v-else href="#" @click="toggleMemberAccount(item.agent.memberAccountId)">{{ item.agent.username }}</a>
              <small v-if="showName" class="text-secondary ml-1">({{item.agent.firstName}})</small>
            </td>
            <td class="text-center">
              <AccountRole :role="item.agent.role" />
            </td>
            <td class="text-right px-1 text-success" style="border-right-width: 2px;">{{ item.summary.amount | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': item.summary.memberAmount>0
              }, {
                'text-danger': item.summary.memberAmount<0
              }]"
            >{{ item.summary.memberAmount | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': item.summary.memberCommission>0
              }, {
                'text-danger': item.summary.memberCommission<0
              }]"
            >{{ item.summary.memberCommission | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': item.summary.memberWon>0
              }, {
                'text-danger': item.summary.memberWon<0
              }]"
            >{{ item.summary.memberWon | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              style="border-right-width: 2px;"
              :class="[{
                'text-success': item.summary.memberTotal>0
              }, {
                'text-danger': item.summary.memberTotal<0
              }]"
            >{{ item.summary.memberTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.agentAmount>0
              }, {
                'text-danger': item.summary.agentAmount<0
              }]">{{ item.summary.agentAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.agentCommission>0
              }, {
                'text-danger': item.summary.agentCommission<0
              }]">{{ item.summary.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.agentWon>0
              }, {
                'text-danger': item.summary.agentWon<0
              }]">{{ item.summary.agentWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1" style="border-right-width: 2px;"
              :class="[{
                'text-success': item.summary.agentTotal>0
              }, {
                'text-danger': item.summary.agentTotal<0
              }]">{{ item.summary.agentTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyAmount>0
              }, {
                'text-danger': item.summary.companyAmount<0
              }]">{{ item.summary.companyAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyCommission>0
              }, {
                'text-danger': item.summary.companyCommission<0
              }]">{{ item.summary.companyCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyWon>0
              }, {
                'text-danger': item.summary.companyWon<0
              }]">{{ item.summary.companyWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': item.summary.companyTotal>0
              }, {
                'text-danger': item.summary.companyTotal<0
              }]">{{ item.summary.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
        </tbody>
        <tbody v-if="!accounts.length && !isLoading">
          <tr>
            <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tbody v-if="isLoading">
          <tr>
            <td colspan="15" class="text-center">กรุณารอซักครู่...</td>
          </tr>
        </tbody>
        <tfoot v-if="accounts.length && !isLoading">
          <tr>
            <th colspan="2" class="text-center">รวม</th>
            <td class="text-right px-1 text-success" style="border-right-width: 2px;">{{ summary.amount | amountFormat(2, '0.00') }}</td>

            <td
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberAmount>0
              }, {
                'text-danger': summary.memberAmount<0
              }]"
            >{{ summary.memberAmount | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberCommission>0
              }, {
                'text-danger': summary.memberCommission<0
              }]"
            >{{ summary.memberCommission | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              :class="[{
                'text-success': summary.memberWon>0
              }, {
                'text-danger': summary.memberWon<0
              }]"
            >{{ summary.memberWon | amountFormat(2, '0.00') }}</td>
            <td
              class="text-right px-1"
              style="border-right-width: 2px;"
              :class="[{
                'text-success': summary.memberTotal>0
              }, {
                'text-danger': summary.memberTotal<0
              }]"
            >{{ summary.memberTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentAmount>0
              }, {
                'text-danger': summary.agentAmount<0
              }]">{{ summary.agentAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentCommission>0
              }, {
                'text-danger': summary.agentCommission<0
              }]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.agentWon>0
              }, {
                'text-danger': summary.agentWon<0
              }]">{{ summary.agentWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1" style="border-right-width: 2px;"
              :class="[{
                'text-success': summary.agentTotal>0
              }, {
                'text-danger': summary.agentTotal<0
              }]">{{ summary.agentTotal | amountFormat(2, '0.00') }}</td>

            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyAmount>0
              }, {
                'text-danger': summary.companyAmount<0
              }]">{{ summary.companyAmount | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyCommission>0
              }, {
                'text-danger': summary.companyCommission<0
              }]">{{ summary.companyCommission | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyWon>0
              }, {
                'text-danger': summary.companyWon<0
              }]">{{ summary.companyWon | amountFormat(2, '0.00') }}</td>
            <td class="text-right px-1"
              :class="[{
                'text-success': summary.companyTotal>0
              }, {
                'text-danger': summary.companyTotal<0
              }]">{{ summary.companyTotal | amountFormat(2, '0.00') }}</td>
          </tr>
        </tfoot>
      </table>
    </b-card-body>
  </div>
</template>
<script>
import ReportService from "@/services/ReportService"
import Swal from 'sweetalert2'
import _ from 'lodash'
import MemberBetNumbers from '@/views/setting-report/components/MemberBetNumbers'
import cAlert from '@/helpers/alert'

export default {
  name: 'RoundReportByMember',
  components: {
    MemberBetNumbers
  },
  props: ['accountId', 'reloadCode'],
  data() {
    return {
      data: null,
      showName: false,
      isLoading: false,
      display: 'Agent',
      memberAccountId: null
    }
  },
  computed: {
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    changed() {
      return `${this.roundId}-${this.accountId}-${this.reloadCode}`
    },
    accounts() {
      if(!this.data)
        return []

      const reports = this.data.reports.map((report)=>{

        const child = this.data.childs.find((child)=>{
          return child.memberAccountId === report._id.accountId
        })

        const account = this.data.accounts.find((account)=>{
          return account.childAccountId === child.childAccountId
        })

        return {
          ...report,
          agent: account
        }
      })

      const groups = _.groupBy(reports, (report)=>{
        return report.agent.childAccountId
      })

      const accounts = []
      for(const accountId in groups) {

        const summary = groups[accountId].reduce((total, item)=>{
          total.amount += item.amount

          total.memberAmount += item.memberAmount
          total.memberCommission += item.memberCommission
          total.memberWon += item.memberWon
          total.memberTotal += (item.memberAmount + item.memberCommission + item.memberWon)

          total.agentAmount += item.agentAmount
          total.agentCommission += item.agentCommission
          total.agentWon += item.agentWon
          total.agentTotal += (item.agentAmount + item.agentCommission + item.agentWon)

          total.companyAmount += item.companyAmount
          total.companyCommission += item.companyCommission
          total.companyWon += item.companyWon
          total.companyTotal += (item.companyAmount + item.companyCommission + item.companyWon)

          return total
        }, {
          amount: 0,
          memberAmount: 0,
          memberCommission: 0,
          memberWon: 0,
          memberTotal: 0,
          agentAmount: 0,
          agentCommission: 0,
          agentWon: 0,
          agentTotal: 0,
          companyAmount: 0,
          companyCommission: 0,
          companyWon: 0,
          companyTotal: 0
        })

        accounts.push({
          agent: groups[accountId][0].agent,
          summary: summary
        })
      }

      return _.orderBy(accounts, ['agent.username'], ['asc'])
    },
    summary() {
      return this.accounts.reduce((total, item)=>{
        total.amount += item.summary.amount

        total.memberAmount += item.summary.memberAmount
        total.memberCommission += item.summary.memberCommission
        total.memberWon += item.summary.memberWon
        total.memberTotal += item.summary.memberTotal

        total.agentAmount += item.summary.agentAmount
        total.agentCommission += item.summary.agentCommission
        total.agentWon += item.summary.agentWon
        total.agentTotal += item.summary.agentTotal

        total.companyAmount += item.summary.companyAmount
        total.companyCommission += item.summary.companyCommission
        total.companyWon += item.summary.companyWon
        total.companyTotal += item.summary.companyTotal

        return total
      }, {
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberWon: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentWon: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyWon: 0,
        companyTotal: 0
      })
    },
    agentName() {
      if(!this.data)
        return ''

      return this.data?.agent?.username
    }
  },
  watch: {
    changed() {
      this.getReport(this.accountId)
    }
  },
  methods: {
    getReport(accountId = null) {
      if(!this.roundId)
        return

      this.display = 'Agent'
      this.isLoading = true
      ReportService.getRoundReportByMember('win-lose', this.roundId, accountId)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
          this.$emit('data', response.data)
          this.setBreadcrumbs(response.data.agent.parents)
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    toggleChildAccount(accountId) {
      this.$emit('accountId', accountId)
    },
    toggleMemberAccount(accountId) {
      this.display = 'Member'
      this.memberAccountId = accountId
      this.$emit('accountId', this.accountId, accountId)
    },
    setBreadcrumbs(parents) {
      this.$emit('Breadcrumbs', parents)
    },
    setData(data) {
      this.$emit('data', data)
    }
  },
  mounted() {
    this.getReport(this.accountId)
  }
}
</script>
<style lang="scss" scoped>
.table {
  thead {
    tr {
      th {
        text-align: center;
        font-weight: normal;
        font-size: 85%;
      }
    }
  }
  tbody {
    background-color: #FFF;
    tr {
      td {
        padding: 5px;
        vertical-align: middle;
        font-size: 85%;
      }
    }
  }
  tfoot {
    tr {
      td {
        font-size: 85%;
      }
    }
  }
}
</style>
<style lang="scss">
.table-report-account {
  tbody {
    tr {
      td {
        .badge {
          font-size: 85%;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
